<template>
  <div class="main-content h100">
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import "./styles/vars";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<script setup>

</script>