export function buildUrl(base, params) {
    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')
        
    return queryString ? `${base}?${queryString}` : base;
}

export function hasExtensionRoot() {
  return !!document.querySelector('#pitchmeai-root') || !!document.querySelector('[data-wxt-iframe]')
}